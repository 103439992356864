import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Coming soon...</h1>
        <a href="https://twitter.com/dark_prgrmmr" style={{color: '#06D6A0'}}>Twitter</a>
      </header>
    </div>
  );
}

export default App;
